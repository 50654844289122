<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Edit Product</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputCategoryName" class="col-sm-2 col-form-label">Shop Name <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.shop" @blur="validationRule()"
										class="form-control" id="inputCategoryName" disabled>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputCategoryName" class="col-sm-2 col-form-label">Category Name <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.category" @blur="validationRule()"
										class="form-control" id="inputCategoryName" disabled>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.name" @blur="validationRule()"
										class="form-control" id="inputName" disabled>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputQuantity" class="col-sm-2 col-form-label">Quantity<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="number" v-model="productRequest.quantity" @blur="validationRule()"
										class="form-control" id="inputQuantity" disabled>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputprice" class="col-sm-2 col-form-label">price<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="number" v-model="productRequest.price" @blur="validationRule()"
										class="form-control" id="inputprice" disabled>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDiscountPrice" class="col-sm-2 col-form-label">Discount price<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="number" v-model="productRequest.discount_price" @blur="validationRule()"
										class="form-control" id="inputDiscountPrice" disabled>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDiscountPercent" class="col-sm-2 col-form-label">Discount Percent<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.discount_percent" @blur="validationRule()"
										class="form-control" id="inputDiscountPercent" disabled>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDiscountDate" class="col-sm-2 col-form-label">Discount Date<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.discount_date" @blur="validationRule()"
										class="form-control" id="inputDiscountDate" disabled>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputExpiredDate" class="col-sm-2 col-form-label">Expired Date<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.expire_date" @blur="validationRule()"
										class="form-control" id="inputExpiredDate" disabled>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputdescription" class="col-sm-2 col-form-label">Description <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<textarea v-model="productRequest.description" @blur="validationRule()"
										class="form-control" id="inputdescription" disabled></textarea>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputStatus" class="col-sm-2 col-form-label">Status <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select id="inputStatus" class="form-control" v-model="productRequest.status">
										<option value="2">Pending</option>
										<option value="3">Active</option>
										<option value="4">Inactive</option>
										<option value="5">Reject</option>
									</select>
									<small class="text-danger">{{validationData.status}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputReason" class="col-sm-2 col-form-label">Reason <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<textarea v-model="productRequest.reason" @blur="validationRule()"
										class="form-control" id="inputReason"></textarea>
								</div>
							</div>


							<div class="form-group row" style="display:none;">
								<label for="inputMerchantStatus" class="col-sm-2 col-form-label">Merchant Status <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select id="inputMerchantStatus" class="form-control" v-model="productRequest.merchant_status">
										<option value="1">Draft</option>
										<option value="2">Pending</option>
										<option value="3">Active</option>
										<option value="4">Inactive</option>
									</select>
									<small class="text-danger">{{validationData.status}}</small>
								</div>
							</div>


							<div class="form-group row">
								<label for="inputBanner" class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<div class="form-check">
										<div>
											<input type="checkbox" class="form-check-input"   v-model="productRequest.is_banner" true-value="1" false-value="0" disabled>
											<label for="inputBanner" class="form-check-label">Is Banner </label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputHotdels" class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<div class="form-check">
										<div>
											<input type="checkbox" class="form-check-input" v-model="productRequest.is_hotdeals" true-value="1" false-value="0" disabled>
											<label for="inputHotdels" class="form-check-label">Is Hotdeals </label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<div class="form-check">
										<div>
											<input type="checkbox" id="is_cb_product" class="form-check-input" v-model="productRequest.is_cb_product" true-value="1" false-value="0">
											<label for="is_cb_product" class="form-check-label">Sell product with CB Card+</label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"> Banner Image </label>
								<div class="col-sm-10">
									<img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"> Gallery Image </label>
								
								<div class="col-sm-4">
									<img  v-for="img in galleryImage" :src="env.mediaUrl+img.image" class="banner-image mt-2 mr-2"/>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="my-modal" hide-backdrop hide-footer>
			<template v-slot:modal-name>
				Alert
			</template>
			<div class="d-block text-center">
				<p>You must fill out the required fields before making other changes.</p>
			</div>
		</b-modal>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'productCreate',
		metaInfo: {
            title: "Product Edit",
            titleTemplate: "%s ← HoHoDiDi Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentFile: "",
				currentImage: "",
				galleryImage: "",
				filter: {
					page: "",
                    reverse: "",
                    sort: "",
					shopId: "",
                    name: ""
				},
				productRequest: {
					id: "",
					category: "",
					name: "",
					price: "",
					discount_price: "",
					discount_percent: "",
					description: "",
					specification: "",
					status: "",
					reason: "",
					quantity: "",
					merchant_status: "",
					image: "",
					is_banner: "",
					is_hotdeals : "",
					is_cb_product: "",
				},
				validationData: {
					name: "",
					price: "",
					description: "",
					specification: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				productUpdateAction: 'productUpdateAction',
				productFieldsAction: 'productFieldsAction'
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.productRequest.image = e.target.result
				};
			},
			async getFields(){
				let option = {
					fields: {id: this.productRequest.id}
				}
				await this.productFieldsAction({
					...option
				}).then(res => {
					this.productRequest.name = res.data.name
					this.productRequest.shop = res.data.shop
					this.productRequest.category = res.data.categoryName
					this.productRequest.price = res.data.price
					this.productRequest.discount_price = res.data.discountPrice
					this.productRequest.description = res.data.description
					this.productRequest.discount_date = res.data.discountDate
					this.productRequest.expire_date = res.data.expireDate
					this.productRequest.status = res.data.status
					this.productRequest.reason = res.data.reason
					this.productRequest.quantity = res.data.itemQuantity
					this.productRequest.merchant_status = res.data.merchantStatus
					this.productRequest.discount_percent = res.data.percent
					this.productRequest.is_banner = res.data.isBanner == true ? 1 : 0
					this.productRequest.is_hotdeals = res.data.isHotdeals == true ? 1 : 0
					this.productRequest.is_cb_product = res.data.isCbProduct == true ? 1 : 0
					this.currentImage = res.data.bannerImage
					this.galleryImage = res.data.image
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.productRequest
				await this.productUpdateAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'product-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, shopId: this.filter.shopId, name: this.filter.name, status: ""} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				// if (this.productRequest.name == "") {
				// 	this.validationData.name = "The name field is required."
				// 	isValidation.push(true)
				// } else {
				// 	this.validationData.name = ""
				// 	isValidation.push(false)
				// }
				// if (this.productRequest.price == "") {
				// 	this.validationData.price = "The price field is required."
				// 	isValidation.push(true)
				// } else {
				// 	this.validationData.price = ""
				// 	isValidation.push(false)
				// }
				// if (this.productRequest.description == "") {
				// 	this.validationData.description = "The description field is required."
				// 	isValidation.push(true)
				// } else {
				// 	this.validationData.description = ""
				// 	isValidation.push(false)
				// }
				// if (this.productRequest.specification == "") {
				// 	this.validationData.specification = "The specification field is required."
				// 	isValidation.push(true)
				// } else {
				// 	this.validationData.specification = ""
				// 	isValidation.push(false)
				// }

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
			this.filter.shopId = this.$route.query.shopId
			this.productRequest.id = this.$route.query.id
			this.filter.name = this.$route.query.name
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>